import { useSelector } from "react-redux";
import OnlyIfAuthenticated from "../../state/currentUser/guards/OnlyIfAuthenticated";
import OnlyIfNotAuthenticated from "../../state/currentUser/guards/OnlyIfNotAuthenticated";
import { TokenDto } from "../../utilities/backend/client";
import { RootState } from "../../utilities/redux/store";
import AdminApp from "./Admin/AdminApp";
import HomeLoggedIn from "./User/loggedIn/HomeLoggedIn";

const Home = () => {
  const token: TokenDto = {
    token: useSelector((state: RootState) => state.token.token) || "",
  };

  const isAdmin =
    token && token.token
      ? JSON.parse(atob(token.token.split(".")[1])).role === "Admin"
      : false;

  return (
    <>
      <OnlyIfAuthenticated
        children={isAdmin ? <AdminApp /> : <HomeLoggedIn></HomeLoggedIn>}
      />
      <OnlyIfNotAuthenticated children={<></>} />
    </>
  );
};

export default Home;
