import { Controller, useForm } from "react-hook-form";
import {
  Campaign,
  CampaignClient,
  CreatorCampaign,
} from "../../utilities/backend/client";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getUrlBlobContainerImage } from "../../resources/imageConstants";

const inputLabelStyle = {
  width: "100%",
  font: "14px Poppins, sans-serif",
  color: "#0000008C",
  marginTop: "5px",
};

const inputHeaderLabelStyle = {
  width: "100%",
  fontFamily: "Ubuntu",
  font: "16px Poppins, sans-serif",
  fontWeight: 700,
  color: "#000000D9",
  marginTop: "20px",
};

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const RaiseForm = () => {
  let campaign: Campaign;
  campaign = {
    stage: 0,
  };
  const campaignClient = new CampaignClient();
  const [loading, setLoading] = useState<boolean>(true);

  function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const [submitPressed, setSubmitPressed] = useState<boolean>(false);
  const [sdg, setSdg] = useState<any[]>();
  const [sdgValue, setSdgValue] = useState<any[]>();

  const [theme, setTheme] = useState<any[]>();
  const [themeValue, setThemeValue] = useState<any[]>();

  const [investmentType, setInvestmentType] = useState<any[]>();
  const [investmentTypeValue, setInvestmentTypeValue] = useState<any[]>();

  const [tileImage, setTileImage] = useState<any>(
    campaign?.tileImage
      ? dataURLtoFile(campaign?.tileImage, "tileImage")
      : undefined
  );
  const [image, setImage] = useState<any>(
    campaign?.image ? dataURLtoFile(campaign?.image, "image") : undefined
  );
  const [logo, setLogo] = useState<any>(
    campaign?.logo ? dataURLtoFile(campaign?.logo, "logo") : undefined
  );
  const [pdf, setPdf] = useState<any>(
    campaign?.pdfPresentation
      ? dataURLtoFile(campaign?.pdfPresentation, "pdf")
      : undefined
  );
  const [originalPdfFileName, setPdfName] = useState<string>();

  const [pdfError, setPdfError] = useState<boolean>(false);
  const [tileImageError, setTileImageError] = useState<boolean>(false);
  const [imageError, setImageError] = useState<boolean>(false);
  const [logoError, setLogoError] = useState<boolean>(false);

  const [pdfSizeError, setPdfSizeError] = useState<boolean>(false);
  const [tileImageSizeError, setTileImageSizeError] = useState<boolean>(false);
  const [imageSizeError, setImageSizeError] = useState<boolean>(false);
  const [logoSizeError, setLogoSizeError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    Promise.all([campaignClient.campaign_GetData()])
      .then(async (response) => {
        const result = await response[0];
        setSdg(result.sdg);
        setTheme(result.theme);
        setInvestmentType(result.investmentType);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    setSdgValue(campaign?.sdGs?.split(",").map(Number) || []);
    setThemeValue(campaign?.themes?.split(",").map(Number) || []);
    setInvestmentTypeValue(
      campaign?.investmentTypes?.split(",").map(Number) || []
    );

    setLoading(false);
  }, []);

  const form = useForm<any>({
    defaultValues: {
      name: campaign?.name || "",
      description: campaign?.description || "",
      website: campaign?.website || "",
      terms: campaign?.terms || "",
      min: campaign?.minimumInvestment || "",
      contactFullName: campaign?.contactInfoFullName || "",
      contactAddress: campaign?.contactInfoAddress || "",
      contactEmail: campaign?.contactInfoEmailAddress || "",
      contactPhone: campaign?.contactInfoPhoneNumber || "",
      target: campaign?.target || "",
      sdg: campaign?.sdGs || [],
      theme: campaign?.themes || [],
      investmentType: campaign?.investmentTypes || [],
      stage: campaign?.stage || 0,
      property: campaign?.property || "",
      privateGroupID: campaign?.groupForPrivateAccessDto?.id || "",
    },
  });

  const { control, register, handleSubmit, formState } = form;
  const { errors } = formState;

  const compressImage = (file: File, imageType: string) => {
    return new Promise<File>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const MAX_WIDTH = 1920;
        const MAX_HEIGHT = 1920;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        canvas.width = width;
        canvas.height = height;

        ctx?.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(new File([blob], file.name, { type: file.type }));
            }
          },
          imageType,
          0.9
        );
      };
    });
  };

  const uploadTileImageChange = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!isValidFile(file)) {
      setTileImageError(true);
      return;
    }

    if (!file || file.size >= 73728000) {
      setTileImageSizeError(true);
      return;
    }

    setTileImageError(false);
    setTileImageSizeError(false);
    const imageType = file.type;
    const compressedFile = await compressImage(file, imageType);
    const base64 = await toBase64(compressedFile);
    setTileImage(base64);
  };

  const uploadImageChange = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!isValidFile(file)) {
      setImageError(true);
      return;
    }

    if (!file || file.size >= 73728000) {
      setImageSizeError(true);
      return;
    }

    setImageError(false);
    setImageSizeError(false);
    const imageType = file.type;
    const compressedFile = await compressImage(file, imageType);
    const base64 = await toBase64(compressedFile);
    setImage(base64);
  };

  const uploadPdfChange = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!isValidPdf(file)) return;

    if (!file || file.size >= 20971520) {
      setPdfSizeError(true);
      return;
    }

    setPdfSizeError(false);
    setPdf(await toBase64(e.target.files[0]));
    const originalPdfFileName = file.name;
    setPdfName(originalPdfFileName);
  };

  const uploadLogoChange = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!isValidFile(file)) {
      setLogoError(true);
      return;
    }

    if (!file || file.size >= 73728000) {
      setLogoSizeError(true);
      return;
    }

    setLogoError(false);
    setLogoSizeError(false);
    const imageType = file.type;
    const compressedFile = await compressImage(file, imageType);
    const base64 = await toBase64(compressedFile);
    setLogo(base64);
  };

  const isValidFile = (file: File) => {
    const allowedExtensions = [".png", ".jpg", ".jpeg", ".gif"];
    const fileExtension = getFileExtension(file.name);

    if (!allowedExtensions.includes(fileExtension)) {
      return false;
    }

    return true;
  };

  const isValidPdf = (file: File) => {
    const allowedExtensions = [".pdf"];
    const fileExtension = getFileExtension(file.name);

    if (!allowedExtensions.includes(fileExtension)) {
      setPdfError(true);
      return false;
    }

    setPdfError(false);
    return true;
  };

  const getFileExtension = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    return fileName.substring(lastDotIndex);
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const onSubmit = async (data: any) => {
    if (tileImage && image && logo && pdf) {
      try {
        setLoading(true);
        let requestCampaign: Campaign = {
          isActive: true,
          contactInfoAddress: data.address1,
          contactInfoEmailAddress: data.email,
          contactInfoFullName: `${data.firstName} ${data.lastName}`,
          contactInfoPhoneNumber: data.contactInfoPhoneNumber,
          description: data.description,
          target: data.fundraisingGoal,
          investmentTypes: data.investmentType.toString(),
          name: data.name,
          minimumInvestment: data.min,
          approvedBy: null,
          themes: data.theme.toString(),
          website: data.website,
          terms: data.terms,
          sdGs: data.sdg.toString(),
          stage: 0,
        };

        const creatorCampaign: CreatorCampaign = {
          firstName: data.firstName,
          lastName: data.lastName,
          userEmail: data.email,
          userMobileNumber: data.contactInfoPhoneNumber,
          userAddressLine1: data.address1,
          userAddressLine2: data.address2,
          city: data.city,
          state: data.state,
          zipCode: data.zipCode,
          fundraisingGoal: data.fundraisingGoal,
          fundraisingCloseDate: data.fundraisingCloseDate,
          impactAssetsFundingStatus: data.impactAssetsFundingStatus,
          investmentRole: data.investmentRole,
          campaign: requestCampaign,
        };

        requestCampaign.tileImage =
          typeof tileImage === "string" ? tileImage : "";
        requestCampaign.image = typeof image === "string" ? image : "";
        requestCampaign.pdfPresentation = typeof pdf === "string" ? pdf : "";
        requestCampaign.logo = typeof logo === "string" ? logo : "";
        requestCampaign.originalPdfFileName = originalPdfFileName;

        await campaignClient
          .campaign_PostRaiseMoneyCampaign(creatorCampaign)
          .then(async () => {
            window.location.href =
              "https://catacap.org/raise-money-form-complete";
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const validateEmail = (value: string | null | undefined) => {
    if (!value) return "Email is required";
    if (value === "ken@arizae.com")
      return "Invalid email. Please, use different email";

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(value)) {
      return "Invalid email format. Template: someone@mail.com";
    }

    return true;
  };

  const validatePhoneNumber = (value: string | null | undefined) => {
    if (!value) return "Phone number is required";
    if (
      typeof value !== "string" ||
      !value.match(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)
    )
      return "Invalid phone number.";
    return true;
  };

  const handleInvestmentTypeMultiselectChange = (
    event: SelectChangeEvent<any>
  ) => {
    const {
      target: { value },
    } = event;
    setInvestmentTypeValue(value);
    form.clearErrors("investmentType");
  };

  return (
    <Box sx={{ margin: "0 auto" }}>
      {loading ? (
        <Box
          sx={{ margin: "0 auto", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{ margin: "auto", mt: "50px", mb: "50px", maxWidth: "1100px" }}
        >
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Your Name<span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{ width: "100%" }}>
                  <TextField
                    type="text"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message?.toString()}
                    {...register("firstName", {
                      required: "First Name is required",
                    })}
                  />
                  <Typography sx={{ ...inputLabelStyle }}>First</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{ width: "100%" }}>
                  <TextField
                    type="text"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message?.toString()}
                    {...register("lastName", {
                      required: "Last Name is required",
                    })}
                  />
                  <Typography sx={{ ...inputLabelStyle }}>Last</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Email<span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth sx={{ width: "100%" }}>
                  <TextField
                    {...register("email", {
                      required: "Email is required",
                      validate: validateEmail,
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message?.toString()}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Mobile Number<span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth sx={{ width: "100%" }}>
                  <TextField
                    type="text"
                    {...register("contactInfoPhoneNumber", {
                      validate: validatePhoneNumber,
                      required: "Mobile Number is required",
                    })}
                    error={!!errors.contactInfoPhoneNumber}
                    helperText={errors.contactInfoPhoneNumber?.message?.toString()}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Address
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth sx={{ width: "100%" }}>
                  <TextField
                    {...register("address1", {
                      required: "Address Line 1 is required",
                    })}
                    error={!!errors.address1}
                    helperText={errors.address1?.message?.toString()}
                  />
                  <Typography sx={{ ...inputLabelStyle }}>
                    Address Line 1
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={10}>
                <FormControl fullWidth sx={{ width: "80%" }}>
                  <TextField {...register("address2")} />
                  <Typography sx={{ ...inputLabelStyle }}>
                    Address Line 2
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{ width: "100%" }}>
                  <TextField
                    type="text"
                    error={!!errors.city}
                    helperText={errors.city?.message?.toString()}
                    {...register("city")}
                  />
                  <Typography sx={{ ...inputLabelStyle }}>City</Typography>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth sx={{ width: "100%" }}>
                  <Select {...register("state")}>
                    {states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography sx={{ ...inputLabelStyle }}>State</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    error={!!errors.zip}
                    helperText={errors.zip?.message?.toString()}
                    {...register("zipCode")}
                  />
                  <Typography sx={{ ...inputLabelStyle }}>Zip Code</Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Investment Name<span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    error={!!errors.name}
                    helperText={errors.name?.message?.toString()}
                    {...register("name", {
                      required: "Investment Name is required",
                      maxLength: {
                        value: 100,
                        message: "Investment Name cannot exceed 100 characters",
                      },
                    })}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  About the Investment (Not to exceed 2,000 characters)
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    multiline
                    rows={8}
                    type="text"
                    error={!!errors.description}
                    helperText={errors.description?.message?.toString()}
                    {...register("description", {
                      required: "Description is required",
                      maxLength: {
                        value: 2000,
                        message: "Description cannot exceed 2000 characters",
                      },
                    })}
                  ></TextField>
                  <Typography sx={{ ...inputLabelStyle }}>
                    0 of 2000 max characters.
                  </Typography>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Investment website URL<span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    error={!!errors.website}
                    helperText={errors.website?.message?.toString()}
                    {...register("website", {
                      required: "Website is required",
                    })}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Type of Investment (Select all that apply)
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              {investmentType !== undefined && (
                <Grid item xs={12} sm={8}>
                  <FormControl sx={{ width: "100%", m: 0, p: 0 }}>
                    <Select
                      multiple
                      value={investmentTypeValue}
                      error={!!errors.investmentType}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 162,
                            width: "auto",
                          },
                        },
                      }}
                      {...register("investmentType", {
                        required: "Investment Type is required",
                      })}
                      onChange={handleInvestmentTypeMultiselectChange}
                    >
                      {investmentType?.map((i) => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.investmentType && (
                      <FormHelperText className="Mui-error">
                        Investment Type is required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Investment Terms (provide 3-10 bullet points of key terms)
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    multiline
                    rows={6}
                    type="text"
                    error={!!errors.terms}
                    helperText={errors.terms?.message?.toString()}
                    {...register("terms", {
                      required: "Terms is required",
                      maxLength: {
                        value: 2000,
                        message: "Terms cannot exceed 2000 characters",
                      },
                    })}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Fundraising Goal ($US)<span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    error={!!errors.fundraisingGoal}
                    helperText={errors.fundraisingGoal?.message?.toString()}
                    {...register("fundraisingGoal", {
                      required: "Fundraising Goal is required",
                    })}
                  ></TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Expected Fundraising Close Date or Evergreen?
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl fullWidth>
                  <TextField
                    multiline
                    type="text"
                    error={!!errors.fundraisingCloseDate}
                    helperText={errors.fundraisingCloseDate?.message?.toString()}
                    {...register("fundraisingCloseDate", {
                      required: "Fundraising Close Date is required",
                    })}
                  ></TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Investment Themes Covered (Select all that apply)
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>

              {theme?.map((i) => (
                <Grid item xs={6} key={i.id}>
                  <Controller
                    name="theme"
                    control={control}
                    defaultValue={themeValue}
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.includes(i.id)}
                            onChange={(e) => {
                              const newValue = e.target.checked
                                ? [...value, i.id]
                                : value.filter(
                                    (currentId: number | string) =>
                                      currentId !== i.id
                                  );
                              onChange(newValue);
                            }}
                            value={i.id}
                          />
                        }
                        label={i.name}
                      />
                    )}
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <Controller
                  name="theme"
                  control={control}
                  render={({ fieldState: { error } }) =>
                    error ? (
                      <FormHelperText error>{error.message}</FormHelperText>
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  SDGs impacted by investment (Select all that apply)
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>

              {sdg?.map((i) => (
                <Grid item xs={6} key={i.id}>
                  <Controller
                    name="sdg"
                    control={control}
                    defaultValue={sdgValue}
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value.includes(i.id)}
                            onChange={(e) => {
                              const newValue = e.target.checked
                                ? [...value, i.id]
                                : value.filter(
                                    (currentId: number | string) =>
                                      currentId !== i.id
                                  );
                              onChange(newValue);
                            }}
                            value={i.id}
                          />
                        }
                        label={i.name}
                      />
                    )}
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <Controller
                  name="sdg"
                  control={control}
                  render={({ fieldState: { error } }) =>
                    error ? (
                      <FormHelperText error>{error.message}</FormHelperText>
                    ) : (
                      <></>
                    )
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Company / Investment Logo (max file size 70 MB)
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <label htmlFor="upload-logo-input">
                    <Button
                      color="primary"
                      variant="outlined"
                      component="span"
                      startIcon={
                        !logo && (
                          <svg
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="inbox"
                            width="30px"
                            height="30px"
                            fill="#000000C3"
                            aria-hidden="true"
                          >
                            <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
                          </svg>
                        )
                      }
                      sx={{
                        textTransform: "none",
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        justifyContent: "space-around",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "8px",
                      }}
                    >
                      <input
                        id="upload-logo-input"
                        hidden
                        onChange={uploadLogoChange}
                        accept=".png, .gif, .jpg"
                        type="file"
                      />
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 16,
                          fontWeight: 400,
                          color: "#474747",
                          marginTop: "8px",
                        }}
                        align="center"
                      >
                        {logo ? (
                          <Avatar
                            src={
                              logo
                                ? logo
                                : getUrlBlobContainerImage(
                                    campaign?.logoFileName!
                                  )
                            }
                            sx={{ width: 100, height: 100 }}
                            variant="rounded"
                          />
                        ) : (
                          " Click or drag a file to this area to upload."
                        )}
                      </Typography>
                    </Button>
                  </label>
                  <Typography sx={{ ...inputLabelStyle }}>
                    Please provide a .png, .gif, or .jpg logo file
                  </Typography>
                  {logoError ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      Only image files are allowed
                    </Typography>
                  ) : logoSizeError ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      File size must be less than 70 MB
                    </Typography>
                  ) : submitPressed && !logo ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      Investment Logo is required
                    </Typography>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Company / Investment Profile Image (max file size 70 MB, 763 x
                  400 px)
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <label htmlFor="upload-profile-input">
                    <Button
                      color="primary"
                      variant="outlined"
                      component="span"
                      startIcon={
                        !image && (
                          <svg
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="inbox"
                            width="30px"
                            height="30px"
                            fill="#000000C3"
                            aria-hidden="true"
                          >
                            <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
                          </svg>
                        )
                      }
                      sx={{
                        textTransform: "none",
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        justifyContent: "space-around",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "8px",
                      }}
                    >
                      <input
                        id="upload-profile-input"
                        hidden
                        onChange={uploadImageChange}
                        accept=".png, .gif, .jpg"
                        type="file"
                      />
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 16,
                          fontWeight: 400,
                          color: "#474747",
                          marginTop: "8px",
                        }}
                        align="center"
                      >
                        {image ? (
                          <Avatar
                            src={
                              image
                                ? image
                                : getUrlBlobContainerImage(
                                    campaign?.imageFileName!
                                  )
                            }
                            sx={{ width: 100, height: 100 }}
                            variant="rounded"
                          />
                        ) : (
                          " Click or drag a file to this area to upload."
                        )}
                      </Typography>
                    </Button>
                  </label>
                  <Typography sx={{ ...inputLabelStyle }}>
                    Please provide a .png, .gif, or .jpg image exactly 763 x 400
                    pixels that will serve as the profile picture for your
                    investment page. You can see an example of a profile image
                    at https://app.catacap.org/invest/26
                  </Typography>
                  {imageError ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      Only image files are allowed
                    </Typography>
                  ) : imageSizeError ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      File size must be less than 70 MB
                    </Typography>
                  ) : submitPressed && !image ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      Investment Profile Image is required
                    </Typography>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Company / Investment Smaller Image (max file size 70 MB, 362 x
                  250 px)
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <label htmlFor="upload-smaller-input">
                    <Button
                      color="primary"
                      variant="outlined"
                      component="span"
                      startIcon={
                        !tileImage && (
                          <svg
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="inbox"
                            width="30px"
                            height="30px"
                            fill="#000000C3"
                            aria-hidden="true"
                          >
                            <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
                          </svg>
                        )
                      }
                      sx={{
                        textTransform: "none",
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        justifyContent: "space-around",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "8px",
                      }}
                    >
                      <input
                        id="upload-smaller-input"
                        hidden
                        onChange={uploadTileImageChange}
                        accept=".png, .gif, .jpg"
                        type="file"
                      />
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 16,
                          fontWeight: 400,
                          color: "#474747",
                          marginTop: "8px",
                        }}
                        align="center"
                      >
                        {tileImage ? (
                          <Avatar
                            src={
                              tileImage
                                ? tileImage
                                : getUrlBlobContainerImage(
                                    campaign?.tileImageFileName!
                                  )
                            }
                            sx={{ width: 100, height: 100 }}
                            variant="rounded"
                          />
                        ) : (
                          " Click or drag a file to this area to upload."
                        )}
                      </Typography>
                    </Button>
                  </label>
                  <Typography sx={{ ...inputLabelStyle }}>
                    Please provide a .png, .gif, or .jpg image exactly 362 x 250
                    pixels that will serve as the tile image for your investment
                    on the search pages.
                  </Typography>
                  {tileImageError ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      Only image files are allowed
                    </Typography>
                  ) : tileImageSizeError ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      File size must be less than 70 MB
                    </Typography>
                  ) : submitPressed && !tileImage ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      Investment Smaller Image is required
                    </Typography>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Company / Investment Pitch Deck (max file size 20 MB)
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <label htmlFor="upload-pdf-input">
                    <Button
                      color="primary"
                      variant="outlined"
                      component="span"
                      startIcon={
                        !pdf && (
                          <svg
                            viewBox="0 0 1024 1024"
                            focusable="false"
                            data-icon="inbox"
                            width="30px"
                            height="30px"
                            fill="#000000C3"
                            aria-hidden="true"
                          >
                            <path d="M885.2 446.3l-.2-.8-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7-.2.8c-1.3 4.9-1.7 9.9-1 14.8-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8 33.7 0 65-9.4 90.3-27.2 22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z"></path>
                          </svg>
                        )
                      }
                      sx={{
                        textTransform: "none",
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        justifyContent: "space-around",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "8px",
                      }}
                    >
                      <input
                        id="upload-pdf-input"
                        hidden
                        onChange={uploadPdfChange}
                        accept=".pdf"
                        type="file"
                      />
                      <Typography
                        sx={{
                          fontFamily: "Ubuntu",
                          fontSize: 16,
                          fontWeight: 400,
                          color: "#000000B3",
                          marginTop: "8px",
                        }}
                        align="center"
                      >
                        {originalPdfFileName
                          ? originalPdfFileName
                          : " Click or drag a file to this area to upload."}
                      </Typography>
                    </Button>
                  </label>
                  <Typography sx={{ ...inputLabelStyle }}>
                    Please provide a .pdf file pitch deck you currently share
                    with potential investors or intend to share with potential
                    investors. This will only be viewed by the CataCap team at
                    this time but will help us better understand the investment
                    and your goals.
                  </Typography>
                  {pdfError ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      Only PDF files are allowed
                    </Typography>
                  ) : pdfSizeError ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      File size must be less than 20 MB
                    </Typography>
                  ) : submitPressed && !pdf ? (
                    <Typography
                      sx={{
                        width: "100%",
                        fontFamily: "Ubuntu",
                        fontSize: "0.75rem",
                        lineHeight: 1.66,
                        letterSpacing: "0.03333em",
                        fontWeight: 400,
                        color: "#d32f2f",
                        marginBottom: "8px",
                      }}
                      align="left"
                    >
                      Investment Pitch Deck is required
                    </Typography>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Have you received funding from Impact Assets before?
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ width: "60%" }}>
                  <Select
                    defaultValue="No"
                    {...register("impactAssetsFundingStatus")}
                  >
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="Not sure">Not sure</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ ...inputHeaderLabelStyle }}>
                  Your role with the investment
                  <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ width: "60%" }}>
                  <Select
                    defaultValue="Company/Fund Executive"
                    {...register("investmentRole")}
                  >
                    <MenuItem value="Company/Fund Executive">
                      Company/Fund Executive
                    </MenuItem>
                    <MenuItem value="Investor">Investor</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setSubmitPressed(true);
                    handleSubmit(onSubmit)();
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default RaiseForm;
